// IMPORTS
@import (reference) "../settings.less";

// MAIN BANNER
.main-banners-wrapper {
    .grid();
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
}

.main-banner {
    aspect-ratio: ~"552/250";
}

// MAINPAGE GREETING
.mainpage-greeting {
    padding: var(--rowGap) 140px;
    background: @light-bg;
    text-align: center;

    .headline {
        margin-bottom: 24px !important;
    }
}